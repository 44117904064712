import React, { useContext } from 'react';
import styled from 'styled-components';
import ProfileEdit from './ProfileEdit';
import AuthContext from 'contexts/auth';
import { RightTabContainer, SplitTabContainer } from 'ui/tabs';
import { Container } from 'ui/containers';
import { Button } from 'ui';
import { useTranslation } from 'react-i18next';
import ForgotPassword from './ForgotPassword';
import { convertToSlug } from './ConvertToSlug';

const Block = styled.div`
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  max-width: 40em;

  ${Button} {
    margin-top: ${({ theme }) => theme.spacing()};
  }
`;

const TitleBlock = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const BlockContainer = styled.div`
  margin-bottom: 128px;
`;

const ProfilAccount = () => {
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const tabContents = [
    <BlockContainer id={convertToSlug(t('profiles.header.accountInfo'))}>
      <TitleBlock>{t('profiles.details.info')}</TitleBlock>
      <Block>
        <ProfileEdit {...user} />
      </Block>
    </BlockContainer>,
    <BlockContainer id={convertToSlug(t('profiles.header.accountPassword'))}>
      <TitleBlock>{t('profiles.account.security.subtitlePassword')}</TitleBlock>
      <Block>
        <ForgotPassword {...user} />
      </Block>
    </BlockContainer>,
    <BlockContainer id={convertToSlug(t('profiles.header.accountPay'))}>
      <TitleBlock>{t('profiles.account.payment.title')}</TitleBlock>
      <Block>
        <div>{t('profiles.account.payment.content')}</div>
      </Block>
    </BlockContainer>,
  ];

  return (
    <Container noMargin style={{ marginTop: 0 }}>
      <SplitTabContainer>
        <RightTabContainer>{tabContents}</RightTabContainer>
      </SplitTabContainer>
    </Container>
  );
};

export default ProfilAccount;
