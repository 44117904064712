import React, { forwardRef } from 'react';
import { Formik } from 'formik';
import { Form } from '../../Forms';
import isString from 'lodash/isString';
import FormValues from '../../FormValues';

const ProfileTravelerModalFields = forwardRef(
  ({ label, fragments, initialValues, onSubmit, onChange, isGrid, hidden }, ref) => {
    const choices = (
      <>
        {(fragments || []).map(fragment => {
          if (!isString(fragment)) {
            return fragment;
          }
          return '';
        })}
      </>
    );

    return (
      <div className={hidden && 'hidden'}>
        <div className="mb-8 font-bold">{label}</div>

        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <FormValues onChangeValues={onChange}/>

              {!isGrid ? (
                <div>{choices}</div>
              ) : (
                <div>
                  {choices}
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  },
);

export default ProfileTravelerModalFields;
