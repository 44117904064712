import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Form } from 'components/Forms';
import { useTranslation } from 'react-i18next';
import { Button, Buttons } from 'ui';
import { forgotPassword } from 'api/auth';

const InnerTitle = styled.p`
  font-size: 1.25rem;
  font-weight: 600;
`;

const ForgotPassword = ({ email }) => {
  const handleUpdatePassword = async values => {
    await forgotPassword({ email: email });
  };
  const { t } = useTranslation();
  return (
    <div>
      <InnerTitle>{t('profiles.account.security.textReset')}</InnerTitle>
      <Formik
        initialValues={{
          email: email,
        }}
      >
        {({ values }) => (
          <Form
            onSubmit={e => {
              e.preventDefault();
              handleUpdatePassword(values);
            }}
          >
            <Buttons isLeftAlign>
              <Button variant="updateProfile">{t('utils.send')}</Button>
            </Buttons>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
