import React, { useContext } from 'react';
import styled from 'styled-components';
import { Container } from 'ui/containers';
import { RightTabContainer, SplitTabContainer } from 'ui/tabs';
import { useTranslation } from 'react-i18next';
import AuthContext from '../contexts/auth';
import { useLocation } from '@reach/router';
import History from './History';
import Sponsorship from './Sponsorship';
import { useAsync } from 'react-async';
import { getMoneyPots } from '../api/profiles';
import { convertToSlug } from './ConvertToSlug';

const TitleBlock = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const BlockContainer = styled.div`
  margin-bottom: 128px;
`;

const ProfilMoney = () => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const location = useLocation();

  const { inviteCode } = user || '';

  const { data: { moneyPot } = {} } = useAsync({ promiseFn: getMoneyPots });

  const invitationUrl = `${location.origin}/?register=true&invitation_code=${inviteCode}`;

  const tabContents = [
    <BlockContainer id={convertToSlug(t('profiles.header.money'))}>
      {moneyPot?.balancePrice !== 0 ? (
        <History moneyPot={moneyPot} />
      ) : (
        <>
          <TitleBlock>{t('profiles.money.history.title')}</TitleBlock>
          <div>{t('profiles.money.history.content')}</div>
        </>
      )}
    </BlockContainer>,
    <BlockContainer id={convertToSlug(t('profiles.header.moneySponsorship'))}>
        <Sponsorship invitationUrl={invitationUrl} code={inviteCode} />
    </BlockContainer>
  ];

  return (
    <Container>
      <SplitTabContainer>
        <RightTabContainer>{tabContents}</RightTabContainer>
      </SplitTabContainer>
    </Container>
  );
};

export default ProfilMoney;
