import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async';
import { getEarnings } from '../api/profiles';
import { journeyWithYearFormatDate } from 'utils/date';
import { AiFillCheckCircle, AiOutlineFieldTime } from 'react-icons/ai';
import { Button, media } from 'ui';
import Wallet from '../assets/images/Wallet.svg';
import { Link } from '@reach/router';
import PageContext from 'contexts/page';

const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const Balance = styled.div`
  display: flex;
  justify-content: left;
  font-weight: bold;
  margin-left: -20px;
  ${media.desktop`
    margin-left: -40%;
  `};
  font-size: 1.8rem;
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;

const Waiting = styled.div`
  display: flex;
  justify-content: center;
  color: grey;
  ${media.desktop`
    margin-left: -80%;
  `};

  ${media.mobile`
  margin-left: -40%;
`};

`;

const Earnings = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(0.8)};
  margin-bottom: ${({ theme }) => theme.spacing(0.8)};
`;

const Date = styled.span`
  color: #858585;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

const Price = styled.span`
  font-weight: bold;
`;

const PriceContainer = styled.div`
${media.mobile`
  text-align: right;
`};
`
const State = styled.span`
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

const Blank = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(-1)};
`;

const CardCagnotte = styled.div`
  background: #f7f7f7;
  border-radius: 25px;
  display: flex;
  align-content: stretch;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 25px;
  ${media.mobile`
    align-items: center;
    padding-top: 10px;
    padding-bottom: 25px;
    flex-wrap: wrap;
  `};
`;

const WalletImage = styled.img`
  display: block;
  ${media.desktop`
    margin-left: -30px;
  `}

  ${media.mobile`
    margin-left: 13px;
  `}
  
`;

const Description = styled.div`
  width: 420px;
  ${media.mobile`
    width: 230px;
  `}
`;
const BlockMoney = styled.div``;

const LinkHome = styled(Link)`
  color: #000;
  text-decoration: none;
`;

const History = ({ moneyPot }) => {
  const { t } = useTranslation();
  const { data: { earnings } = [] } = useAsync({ promiseFn: getEarnings });
  const { language } = useContext(PageContext);

  return (
    <>
      <Title>{t('profiles.money.history.balance')}</Title>
      <CardCagnotte>
        <WalletImage src={Wallet} alt="image portefeuille" />
        <BlockMoney>
          <Balance>{moneyPot?.balancePrice} €</Balance>
          <Waiting>{`${moneyPot?.waitingPrice} € 
            ${t('profiles.money.history.waiting',)}`}
          </Waiting>  
        </BlockMoney>
        <Button variant="pictureProfile" cagnotte>
          <LinkHome to={language === 'fr' ? '/' : '/' + language}>
            {t('profiles.money.history.buttonSpend')}
          </LinkHome>
        </Button>
        <Blank />
      </CardCagnotte>
      {(earnings || []).map((earning, index) => (
        <div key={index}>
          <Earnings>
            <Description>
              <Date>{`${journeyWithYearFormatDate(
                earning.createdAt,
              )}`}</Date>
              <span>{`${
                earning.kind === 'sponsorship'
                  ? t('profiles.money.history.bonus')
                  : earning.displayName
              }`}</span>
            </Description>
            <PriceContainer>
              <Price>{`${earning.price} €`}</Price>
              <State>
                {earning.state === 'waiting' ? (
                  <AiOutlineFieldTime fill="#ea8e00" size={23} />
                ) : (
                  <AiFillCheckCircle
                    fill="#73c992"
                    size={23}
                    style={{ marginBottom: '4px' }}
                  />
                )}
              </State>
            </PriceContainer>
          </Earnings>
        </div>
      ))}
    </>
  );
};

export default History;
