import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async';
import { getEarnings } from '../api/profiles';
import { journeyWithYearFormatDate } from 'utils/date';
import { AiFillCheckCircle, AiOutlineFieldTime } from 'react-icons/ai';
import Spinner from './Spinner';
import { media } from 'ui';

const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const Content = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1.5)};
  text-align: left;
  font: normal normal 600 16px/25px Muli;
  letter-spacing: -0.16px;
  color: #6c6c6c;
`;

const Divider = styled.div`
  border-top: 1px solid #dfe0e0;
  margin-left: ${({ theme }) => theme.spacing(-1)};
  margin-right: ${({ theme }) => theme.spacing(-1)};
`;

const Earnings = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(0.8)};
  margin-bottom: ${({ theme }) => theme.spacing(0.8)};
`;

const Date = styled.span`
  color: #858585;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

const Price = styled.span`
  font-weight: bold;
`;

const State = styled.span`
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

const Blank = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(-1)};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  input {
    width: 100%;
    height: 45px;
    border: 1px solid #e5e0e0;
    border-radius: 10px;
    font: normal normal 600 16px/43px Muli;
    letter-spacing: 0px;
    color: #6c6c6c;
    padding: 10px;
    margin-right: 20px;
    &:focus,
    &:focus-visible {
      border: 2px solid #FFA24B;
    }
  }
  ${media.tablet`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

const Button = styled.button`
  border: 1px solid ${({ theme }) => theme.secondary};
  color: #000;
  font-weight: 700;
  border-radius: 28px !important;
  background-color: ${({ theme }) => theme.secondary};
  width: 150px !important;
  height: 45px;
  ${media.mobile`
     margin-top:  20px ;
  `};
  cursor: pointer;
`;

const Sponsorship = ({ invitationUrl, code }) => {
  const { t } = useTranslation();
  const { data: { earnings } = [] } = useAsync({ promiseFn: getEarnings });
  const [isClicked, setIsCliked] = useState(false);

  const sponsorship = (earnings || []).filter(
    data => data.kind === 'sponsorship',
  );

  const clipboard = () => {
    navigator.clipboard.writeText(invitationUrl);
    setIsCliked(true);
  };

  return (
    <>
      <Title>{t('profiles.money.sponsorship.title')}</Title>
      <Content>{t('profiles.money.sponsorship.content')}</Content>
      {Boolean(code) ? (
        <Wrapper>
          <input isClicked={isClicked} type="text" value={invitationUrl} />
          <Button onClick={clipboard}>
            {t('profiles.money.sponsorship.link')}
          </Button>
        </Wrapper>
      ) : (
        <Spinner />
      )}
      {(sponsorship || []).map((earning, index) => (
        <div key={index}>
          <Divider />
          <Earnings>
            <div>
              <Date>{`${journeyWithYearFormatDate(earning.createdAt)}`}</Date>
              <span>{`${earning.displayName}`}</span>
            </div>
            <div>
              <Price>{`${earning.price} €`}</Price>
              <State>
                {earning.state === 'waiting' ? (
                  <AiOutlineFieldTime fill="#ea8e00" size={23} />
                ) : (
                  <AiFillCheckCircle
                    fill="#73c992"
                    size={23}
                    style={{ marginBottom: '4px' }}
                  />
                )}
              </State>
            </div>
          </Earnings>
        </div>
      ))}
      <Blank />
    </>
  );
};

export default Sponsorship;
