import React, { useContext } from 'react';
import PageContext from '../contexts/page';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import { Router } from '@reach/router';
import ProfilDetails from '../components/ProfilDetails';
import Profile from '../components/Profile';
import { useTranslation } from 'react-i18next';
import { useAuthentication } from '../hooks/index';
import { useDataLayerBuilder } from '@tymate/react-gtm';

const ProfileIndex = () => {
  useAuthentication();
  const { getLanguagePrefix } = useContext(PageContext);
  const { t } = useTranslation();
  useDataLayerBuilder(() => ({
    pageType: 'account',
  }));

  return (
    <Layout>
      <SEO title={t('pageTitle.profile')} />
      <Router>
        <Profile default path={`${getLanguagePrefix(t('url.account'))}`} />
        <ProfilDetails
          path={`${getLanguagePrefix(t('url.account'))}/${t(
            'url.profile',
          )}/:profileId`}
        />
      </Router>
    </Layout>
  );
};

export default ProfileIndex;
