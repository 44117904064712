import React from 'react';
import Button from '../../UI/Button';
import { useTranslation } from 'react-i18next';
import { round } from 'lodash';

export default function ProfileTravelerListItem({ displayName, completionRate, id, setProfileId }) {
  const { t } = useTranslation();
  if (completionRate > 100) {
    completionRate = 100;
  }
  return (
    <div className="border rounded-3xl">
      <div className="flex -xs:flex-col -xs:space-y-4 justify-between items-center px-8 py-8">
        <div className="flex -sm:flex-col">
          <div className="text-lg font-semibold">{displayName}</div>
          <span className="sm:hidden text-gray-500">{round(completionRate, 0)}% {t('profiles.details.complete')}</span>
        </div>

        <div className="flex items-center space-x-5">
          <span className="hidden sm:block text-gray-500">{round(completionRate, 0)}% {t('profiles.details.complete')}</span>
          <Button
            paddingClass="py-2 px-5 text-sm"
            onClick={() => setProfileId(id)}
          >
            {t('utils.edit')}
          </Button>
        </div>
      </div>
    </div>
  )
}
