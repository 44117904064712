import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import { Form } from 'components/Forms';
import { Button, Buttons, SpinnerContainer } from 'ui';
import { useTranslation } from 'react-i18next';
import { updateMe, updateFile } from 'api/auth';
import { DateField } from 'components/SingleDateField';
import styled from 'styled-components';
import NoneImageProfile from '../assets/images/NoneImageProfile.svg';
import Input from './UI/Input';
import Spinner from 'components/Spinner';
import { API_URL } from 'constants';

const CardImage = styled.div`
  border: 1px solid #e7e7e7;
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const PictureProfile = styled.img`
  margin: 5%;
  width: 67px;
  height: 67px;
  border-radius: 30px;
`;

const Action = styled.div`
  position: relative;
  text-align: right;
  top: 17px;
  color: #e0791a;
  font-weight: bold;
  cursor:pointer;
`;

const BlockButton = styled.div``;

const DateLabel = styled.span`
  font-size: 0.75rem;
  line-height: 1rem;
  text-transform: uppercase;
  font-weight: bold;
`;

const ProfileEdit = ({
  email,
  firstName,
  lastName,
  phoneNumber,
  birthday,
  picture,
  address,
  gender
}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [index, setIndex] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [imgData, setImgData] = useState(null);
  const [profilImg, setProfilImg] = useState(null);
  const [actionMessage, setActionMessage] = useState(
    ['firstname', 'lastname', 'email', 'birthday', 'phonenumber', 'gender', 'street', 'postalCode', 'city']
  );

  useEffect(() => {
    if(picture){
      setImgData(picture ? `${API_URL}/${picture.imagePath}` : NoneImageProfile)
    }else{
      setImgData(NoneImageProfile)
    }
  }, [picture]);
    
  const genderOptions = [
    { label: t('modals.register.genders.female'), value: 'female' },
    { label: t('modals.register.genders.male'), value: 'male' },
    { label: t('modals.register.genders.nb'), value: 'noBinary' },
  ];

  const srtAdress =  address?.split(',')

  useEffect(() => {
    if(email){
      setIsLoading(false);
        setActionMessage(prev => ({
          ...prev,
          firstname: firstName ? 0 : 1,
        }));
      setActionMessage(prev => ({
        ...prev,
        lastname: lastName ? 0 : 1,
      }));

      setActionMessage(prev => ({
        ...prev,
        email: email ? 0 : 1,
      }));
      setActionMessage(prev => ({
        ...prev,
        birthday: birthday ? 0 : 1,
      }));
      setActionMessage(prev => ({
        ...prev,
        phonenumber: phoneNumber ? 0 : 1,
      }));
      setActionMessage(prev => ({
        ...prev,
        gender: gender ? 0 : 1,
      }));
      setActionMessage(prev => ({
        ...prev,
        street: srtAdress && srtAdress[0]  ? 0 : 1,
      }));
      setActionMessage(prev => ({
        ...prev,
        postalCode: srtAdress && srtAdress[1]  ? 0 : 1,
      }));
      setActionMessage(prev => ({
        ...prev,
        city: srtAdress && srtAdress[2]  ? 0 : 1,
      }));
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, birthday, lastName, phoneNumber, gender, address]);

  const changeInputState = val =>{
    if(val === 0){
      setActionMessage(prev => ({
          ...prev,
          lastname: lastName ? 0 : 1,
          email: email ? 0 : 1,
          birthday: birthday ? 0 : 1,
          phonenumber: phoneNumber ? 0 : 1,
          gender: gender ? 0 : 1,
          street: srtAdress && srtAdress[0]  ? 0 : 1 ,
          postalCode: srtAdress && srtAdress[1]  ? 0 : 1 ,
          city: srtAdress && srtAdress[2]  ? 0 : 1 ,
        }))
    }
    else if(val === 1 ){
      setActionMessage(prev => ({
        ...prev,
        firstname: firstName ? 0 : 1,
        email: email ? 0 : 1,
        birthday: birthday ? 0 : 1,
        phonenumber: phoneNumber ? 0 : 1,
        gender: gender ? 0 : 1,
        street: srtAdress && srtAdress[0]  ? 0 : 1 ,
        postalCode: srtAdress && srtAdress[1]  ? 0 : 1 ,
        city: srtAdress && srtAdress[2]  ? 0 : 1 ,
      }))
    }
    else if(val === 2 ){
      setActionMessage(prev => ({
        ...prev,
        lastname: lastName ? 0 : 1,
        firstname: firstName ? 0 : 1,
        birthday: birthday ? 0 : 1,
        phonenumber: phoneNumber ? 0 : 1,
        gender: gender ? 0 : 1,
        street: srtAdress && srtAdress[0]  ? 0 : 1 ,
        postalCode: srtAdress && srtAdress[1]  ? 0 : 1 ,
        city: srtAdress && srtAdress[2]  ? 0 : 1 ,
      }))
    }
    else if(val === 3 ){
      setActionMessage(prev => ({
        ...prev,
        lastname: lastName ? 0 : 1,
        email: email ? 0 : 1,
        firstname: firstName ? 0 : 1,
        phonenumber: phoneNumber ? 0 : 1,
        gender: gender ? 0 : 1,
        street: srtAdress && srtAdress[0]  ? 0 : 1 ,
        postalCode: srtAdress && srtAdress[1]  ? 0 : 1 ,
        city: srtAdress && srtAdress[2]  ? 0 : 1 ,
      }))
    }
    else if(val === 4 ){
      setActionMessage(prev => ({
        ...prev,
        lastname: lastName ? 0 : 1,
        email: email ? 0 : 1,
        birthday: birthday ? 0 : 1,
        firstname: firstName ? 0 : 1,
        gender: gender ? 0 : 1,
        street: srtAdress && srtAdress[0]  ? 0 : 1 ,
        postalCode: srtAdress && srtAdress[1]  ? 0 : 1 ,
        city: srtAdress && srtAdress[2]  ? 0 : 1 ,
      }))
    }
    else if(val === 5 ){
      setActionMessage(prev => ({
        ...prev,
        lastname: lastName ? 0 : 1,
        email: email ? 0 : 1,
        birthday: birthday ? 0 : 1,
        firstname: firstName ? 0 : 1,
        street: srtAdress && srtAdress[0]  ? 0 : 1 ,
        postalCode: srtAdress && srtAdress[1]  ? 0 : 1 ,
        city: srtAdress && srtAdress[2]  ? 0 : 1 ,
      }))
    }
    else if(val === 6 ){
      setActionMessage(prev => ({
        ...prev,
        lastname: lastName ? 0 : 1,
        email: email ? 0 : 1,
        birthday: birthday ? 0 : 1,
        firstname: firstName ? 0 : 1,
        gender: gender ? 0 : 1,
        postalCode: srtAdress && srtAdress[1]  ? 0 : 1 ,
        city: srtAdress && srtAdress[2]  ? 0 : 1 ,
      }))
    }
    else if(val === 7 ){
      setActionMessage(prev => ({
        ...prev,
        lastname: lastName ? 0 : 1,
        email: email ? 0 : 1,
        birthday: birthday ? 0 : 1,
        firstname: firstName ? 0 : 1,
        gender: gender ? 0 : 1,
        street: srtAdress && srtAdress[0]  ? 0 : 1 ,
        city: srtAdress && srtAdress[2]  ? 0 : 1 ,
      }))
    }
    else if(val === 8 ){
      setActionMessage(prev => ({
        ...prev,
        lastname: lastName ? 0 : 1,
        email: email ? 0 : 1,
        birthday: birthday ? 0 : 1,
        firstname: firstName ? 0 : 1,
        gender: gender ? 0 : 1,
        street: srtAdress && srtAdress[0]  ? 0 : 1 ,
        postalCode: srtAdress && srtAdress[1]  ? 0 : 1 ,
      }))
    }
    if(actionMessage.gender === 2 
      || actionMessage.firstname === 2 
      || actionMessage.lastname === 2
      || actionMessage.street === 2
      || actionMessage.postalCode === 2
      || actionMessage.city === 2
      || actionMessage.email === 2 
      || actionMessage.birthday === 2 || actionMessage.phonenumber){
        setVisible(true)
      }else{
        setVisible(false)
      }
  }
  useEffect(() => {
    if(index >= 0){
      changeInputState(index)
    }
  },[index] )// eslint-disable-line react-hooks/exhaustive-deps

  
  const handleUpdateMe = async values => {
    await updateMe({
      user: {
        email: values?.email || '',
        firstName: values?.firstName || '',
        lastName: values?.lastName || '',
        phoneNumber: values?.phoneNumber || '',
        birthday: values?.birthday || '',
        address: `${values.address.street}, ${values.address.postalCode}, ${values.address.city}`,
        gender: values.gender,
      },
    });
    setVisible(false)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (profilImg) {
      formData.append('user[picture_attributes][image]', profilImg);
    }
    await updateFile(formData);
    await window.location.reload(false);
};

const selectImage = async (e) => {
  const fileReader = new FileReader();
  let file = e.target.files[0];
  setProfilImg(file);

  fileReader.onloadend = () => {
      setImgData(fileReader.result);
  };

  await fileReader.readAsDataURL(file);
};
useEffect(() => {
  if(profilImg){
    document.getElementById("submitFormFile").click(); 
  }
}, [profilImg]);

  const getLabelAction = key => {
    switch (actionMessage[key]) {
      case 0:
        return t('profiles.details.actionUpdate');
      case 1:
        return t('profiles.details.actionAdd');
      case 2:
        return t('profiles.details.actionCancel');
      default:
        return t('profiles.details.actionUpdate');
    }
  };

  const defaultValue = {
    email: email || '',
    firstName: firstName || '',
    lastName: lastName || '',
    phoneNumber: phoneNumber || '',
    birthday: birthday || '',
    address: {
      street: srtAdress ? srtAdress[0] : '',
      postalCode: srtAdress ? srtAdress[1] : '',
      city:srtAdress ? srtAdress[2] : '', 
    },
    gender: gender || 'female', 
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <CardImage>
          <PictureProfile src={imgData} alt="image profile" />
          <input 
            type="file" 
            name='picture' 
            id="selectedFile" 
            hidden  
            accept="image/*"           
            onChange={selectImage}
          />
          <Button 
            variant="pictureProfile"
            onClick={function(){
              document.getElementById('selectedFile').click();
            }}
            >
            {t('profiles.details.buttonAddPicture')}
          </Button>
          <button type='submit' hidden id="submitFormFile" style={{marginTop: '300px'}}>Enregistrer</button>
        </CardImage>
      </form> 
       {isLoading ? (
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
          ) : ( <>
      <Formik
        initialValues={defaultValue }
        onSubmit={handleUpdateMe}
      >
      {({ resetForm }) => (
        <Form>
          <div className="mb-8">
            <Action
                onClick={function() {
                setIndex(5);
                setVisible(!visible);
                resetForm()
                setActionMessage(prev => ({
                  ...prev,
                   gender: gender ? prev.gender === 0 ? 2 : 0 : 1,
                }));
              }}
            >
              {getLabelAction('gender', 1)}
            </Action>
            <Input
                as="select"
                label={t('modals.register.labels.gender')}
                name="gender"
                isProfile
                autoplaceholder
                onFocus={function() {
                  setActionMessage(prev => ({
                    ...prev,
                    gender: 2,
                  }));
                  setIndex(5);
                  setVisible(true);
                }}
              >
                {genderOptions.map(o => (
                  <option value={o.value}>{o.label}</option>
                ))}
            </Input>
            
            {index === 5 && visible && (
              <BlockButton>
                <Buttons isLeftAlign>
                  <Button type="submit"
                    onClick={() => {
                      setActionMessage(prev => ({
                        ...prev,
                        gender: gender ? prev.gender === 0 ? 2 : 0 : 1,
                      }));
                  }}
                  variant="updateProfile">
                    {t('profiles.details.buttonSave')}
                  </Button>
                </Buttons>
              </BlockButton>
            )}
          </div>
        </Form>
       )}
      </Formik>
      <Formik
        initialValues={defaultValue }
        onSubmit={handleUpdateMe}
      >
      {({ resetForm }) => (
        <Form>
          <div className="mb-8">
            <Action
                onClick={function() {
                setIndex(0);
                setVisible(!visible);
                resetForm()
                setActionMessage(prev => ({
                  ...prev,
                   firstname: firstName ? prev.firstname === 0 ? 2 : 0 : 1,
                }));
              }}
            >
              {getLabelAction('firstname', 1)}
            </Action>
            <Input
              label={t('modals.register.labels.firstname')}
              name="firstName"
              required
              autoplaceholder
              isProfile
              onFocus={function() {
                setIndex(0);
                setVisible(true);
                setActionMessage(prev => ({
                  ...prev,
                  firstname: 2,
                }));
              }}
            />
            {index === 0 && visible && (
              <BlockButton>
                <Buttons isLeftAlign>
                  <Button type="submit"
                    onClick={() => {
                      setActionMessage(prev => ({
                        ...prev,
                        firstname: firstName ? prev.firstname === 0 ? 2 : 0 : 1,
                      }));
                  }}
                  variant="updateProfile">
                    {t('profiles.details.buttonSave')}
                  </Button>
                </Buttons>
              </BlockButton>
            )}
          </div>
        </Form>
       )}
      </Formik>
      
      <Formik
        initialValues={defaultValue }
        onSubmit={handleUpdateMe}
      >
      {({ resetForm }) => (
        <Form>
          <div className="mb-8">
            <Action
              onClick={function() {
                setIndex(1);
                setVisible(!visible);
                resetForm()
                setActionMessage(prev => ({
                  ...prev,
                  lastname: lastName ? prev.lastname === 0 ? 2 : 0 : 1,
                }));
              }}
            >
              {getLabelAction('lastname', 1)}
            </Action>
            <Input
              label={t('modals.register.labels.lastname')}
              name="lastName"
              autoplaceholder
              required
              isProfile
              onFocus={function() {
                setActionMessage(prev => ({
                  ...prev,
                  lastname: 2,
                }));
                setIndex(1);
                setVisible(true);
              }}
            />
            {index === 1 && visible && (
              <BlockButton>
                <Buttons isLeftAlign>
                  <Button type="submit"
                    onClick={function() {
                      setActionMessage(prev => ({
                        ...prev,
                        lastname: lastName ? prev.lastname === 0 ? 2 : 0 : 1,
                      }));
                    }}
                  variant="updateProfile">
                    {t('profiles.details.buttonSave')}
                  </Button>
                </Buttons>
              </BlockButton>
            )}
          </div>
        </Form>
      )}
      </Formik>
      <Formik
        initialValues={defaultValue }
        onSubmit={handleUpdateMe}
      >
      {({ resetForm }) => (
        <Form>
          <div className="mb-8">
            <Action
              onClick={function() {
                setIndex(2);
                setVisible(!visible);
                resetForm();
                setActionMessage(prev => ({
                  ...prev,
                  email: email ? prev.email === 0 ? 2 : 0 : 1,
                }));
              }}
            >
              {getLabelAction('email', 2)}
            </Action>
            <Input
              label={t('modals.register.labels.email')}
              name="email"
              autoplaceholder
              required
              isProfile
              onFocus={function() {
                setActionMessage(prev => ({
                  ...prev,
                  email: 2,
                }));
                setIndex(2);
                setVisible(true);
              }}
            />

            {index === 2 && visible && (
              <BlockButton>
                <Buttons isLeftAlign>
                  <Button 
                    type="submit"
                    onClick={function() {
                      setActionMessage(prev => ({
                        ...prev,
                        email: email ? prev.email === 0 ? 2 : 0 : 1,
                      }));
                    }}
                    variant="updateProfile">
                    {t('profiles.details.buttonSave')}
                  </Button>
                </Buttons>
              </BlockButton>
            )}
          </div>
        </Form>
      )}
      </Formik>

      <Formik
        initialValues={defaultValue }
        onSubmit={handleUpdateMe}
      >
      {({ resetForm }) => (
        <Form>
          <div className="mb-8">
            <Action
              onClick={function() {
                setIndex(3);
                setVisible(!visible);
                resetForm();
                setActionMessage(prev => ({
                  ...prev,
                  birthday: birthday ?  prev.birthday === 0 ? 2 : 0 : 1,
                }));
              }}
            >
              {getLabelAction('birthday', 3)}
            </Action>
            <DateLabel
              onFocus={function() {
                setActionMessage(prev => ({
                  ...prev,
                  birthday: 2,
                }));
                setIndex(3);
                setVisible(true);
              }}
            >
              <span>{t('modals.register.labels.birthday')}  
              <span className="tw ml-0.5 text-red-500">*</span>
           </span>
            </DateLabel>
            <DateField
              variant="profile"
              name="birthday"
              datePlaceholder={t('modals.register.labels.enterBirthday')}
              isVertical={true}
              clearDateDisabled= {true}
              isProfile
              allowPast
              full
              allowFuture
              required
              actionOnfocus={function() {
                setIndex(3);
                setVisible(true);
                setActionMessage(prev => ({
                  ...prev,
                  birthday: 2,
                }));
              }}
            />
              
            {index === 3 && visible && (
              <BlockButton>
                <Buttons isLeftAlign>
                  <Button 
                    type="submit"
                    onClick={function() {
                      setActionMessage(prev => ({
                        ...prev,
                        birthday: birthday ?  prev.birthday === 0 ? 2 : 0 : 1,
                      }));
                    }}
                    variant="updateProfile">
                    {t('profiles.details.buttonSave')}
                  </Button>
                </Buttons>
              </BlockButton>
            )}
          </div>
        </Form>
      )}
      </Formik>
      <Formik
        initialValues={defaultValue }
        onSubmit={handleUpdateMe}
      >
      {({ resetForm }) => (
        <Form>
          <div className="mb-8">
            <Action
                onClick={function() {
                setIndex(6);
                setVisible(!visible);
                resetForm()
                setActionMessage(prev => ({
                  ...prev,
                  street:  srtAdress && srtAdress[2] ? prev.city === 0 ? 2 : 0 : 1,
                }));
              }}
            >
              {getLabelAction('street', 1)}
            </Action>
           
            <Input
                label={t('modals.register.labels.address')}
                name="address.street"
                autoplaceholder
                required
                isProfile
                onFocus={function() {
                  setActionMessage(prev => ({
                    ...prev,
                    street: 2,
                  }));
                  setIndex(6);
                  setVisible(true);
                }}
              />
            
            {index === 6 && visible && (
              <BlockButton>
                <Buttons isLeftAlign>
                  <Button type="submit"
                    onClick={() => {
                      setActionMessage(prev => ({
                        ...prev,
                        street:  srtAdress && srtAdress[2] ? prev.city === 0 ? 2 : 0 : 1,
                      }));
                  }}
                  variant="updateProfile">
                    {t('profiles.details.buttonSave')}
                  </Button>
                </Buttons>
              </BlockButton>
            )}
          </div>
        </Form>
       )}
      </Formik>

      <Formik
        initialValues={defaultValue }
        onSubmit={handleUpdateMe}
      >
      {({ resetForm }) => (
        <Form>
          <div className="mb-8">
            <Action
                onClick={function() {
                setIndex(7);
                setVisible(!visible);
                resetForm()
                setActionMessage(prev => ({
                  ...prev,
                  postalCode:  srtAdress && srtAdress[1] ? prev.city === 0 ? 2 : 0 : 1,
                }));
              }}
            >
              {getLabelAction('postalCode', 1)}
            </Action>
           
            <Input
                label={t('modals.register.labels.postalCode')}
                name="address.postalCode"
                autoplaceholder
                required
                isProfile
                onFocus={function() {
                  setActionMessage(prev => ({
                    ...prev,
                    postalCode: 2,
                  }));
                  setIndex(7);
                  setVisible(true);
                }}
              />
            
            {index === 7 && visible && (
              <BlockButton>
                <Buttons isLeftAlign>
                  <Button type="submit"
                    onClick={() => {
                      setActionMessage(prev => ({
                        ...prev,
                        postalCode:  srtAdress && srtAdress[1] ? prev.city === 0 ? 2 : 0 : 1,
                      }));
                  }}
                  variant="updateProfile">
                    {t('profiles.details.buttonSave')}
                  </Button>
                </Buttons>
              </BlockButton>
            )}
          </div>
        </Form>
       )}
      </Formik>

      <Formik
        initialValues={defaultValue }
        onSubmit={handleUpdateMe}
      >
      {({ resetForm }) => (
        <Form>
          <div className="mb-8">
            <Action
                onClick={function() {
                setIndex(8);
                setVisible(!visible);
                resetForm()
                setActionMessage(prev => ({
                  ...prev,
                  city:  srtAdress && srtAdress[2] ? prev.city === 0 ? 2 : 0 : 1,
                }));
              }}
            >
              {getLabelAction('city', 8)}
            </Action>
           
            <Input
                label={t('modals.register.labels.city')}
                name="address.city"
                autoplaceholder
                required
                isProfile
                onFocus={function() {
                  setActionMessage(prev => ({
                    ...prev,
                    city: 2,
                  }));
                  setIndex(8);
                  setVisible(true);
                }}
              />
            
            {index === 8 && visible && (
              <BlockButton>
                <Buttons isLeftAlign>
                  <Button type="submit"
                    onClick={() => {
                      setActionMessage(prev => ({
                        ...prev,
                        city:  srtAdress && srtAdress[2] ? prev.city === 0 ? 2 : 0 : 1,
                      }));
                  }}
                  variant="updateProfile">
                    {t('profiles.details.buttonSave')}
                  </Button>
                </Buttons>
              </BlockButton>
            )}
          </div>
        </Form>
       )}
      </Formik>


      <Formik
        initialValues={defaultValue }
        onSubmit={handleUpdateMe}
      >
      {({ resetForm }) => (
        <Form>
          <div className="mb-8">
            <Action
              onClick={function() {
                setIndex(4);
                setVisible(!visible);
                resetForm();
                setActionMessage(prev => ({
                  ...prev,
                  phonenumber: phoneNumber ? prev.phonenumber === 0 ? 2 : 0 : 1,
                }));
              }}
            >
              {getLabelAction('phonenumber', 4)}
            </Action>
            <Input
              label={t('modals.register.labels.phoneEmergency')}
              name="phoneNumber"
              autoplaceholder
              required
              isProfile
              onFocus={function() {
                setActionMessage(prev => ({
                  ...prev,
                  phonenumber: 2,
                }));
                setIndex(4);
                setVisible(true);
              }}
            />

            {index === 4 && visible && (
              <BlockButton>
                <Buttons isLeftAlign>
                  <Button 
                    type="submit"
                    onClick={function() {
                      setActionMessage(prev => ({
                        ...prev,
                        phonenumber: phoneNumber ? prev.phonenumber === 0 ? 2 : 0 : 1,
                      }));
                    }}
                    variant="updateProfile">
                    {t('profiles.details.buttonSave')}
                  </Button>
                </Buttons>
              </BlockButton>
            )}
          </div>
        </Form>
      )}
      </Formik> 
      </>
     )}
    </div>
  );
};

export default ProfileEdit;
