import React from 'react';

export default function ProfileTravelerModalMenu({steps, currentStep, setCurrentStep}) {
  return (
    <div className="tw">
      <ul className="pt-2">
        {steps.map((section, index) =>
          <li>
            <div className="relative pb-6">
              {index + 1 < steps.length &&
                <span className="absolute top-1 left-1 -ml-px h-full w-0.5 bg-gray-300"/>
              }
              <div className="relative flex space-x-3">
                <span className={[
                  'h-2 w-2 rounded-full',
                  currentStep === index ? 'bg-brand-green' : 'border-2 border-gray-300 bg-gray-100'
                ].join(' ')}/>

                <div className="min-w-0 flex-1 -mt-2.5">
                  <button
                    className={`text-sm font-semibold ${currentStep !== index && 'opacity-50'}`}
                    onClick={() => setCurrentStep(index)}
                  >
                    {section.label}
                  </button>
                </div>
              </div>
            </div>
          </li>
        )}
      </ul>
    </div>
  )
}
