import React, { useEffect, createRef, useContext, useState } from 'react';
import styled from 'styled-components';
import { Container } from 'ui/containers';
import {
  TextLink,
  Paragraph,
  Button,
  Buttons,
  RightContainer,
  LeftContainer,
  SplitContainer,
  PrimaryLink,
} from 'ui';
import ProfileFields from './Profile/Traveler/ProfileTravelerModalFields';
import useProfileEditor from 'hooks/profileEditor';
import { useTranslation } from 'react-i18next';
import { Link } from 'gatsby';
import { formatDate } from 'utils/date';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import PageContext from 'contexts/page';
import Modal from 'components/Modal';
import { deleteProfile } from '../api/profiles';
import { navigate } from '@reach/router';
import Spinner from 'components/Spinner';
import { media } from 'ui';

const ProgressLine = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.12);
`;

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 2px;
  right: 0;
  border-bottom: 2px solid ${({ theme }) => theme.primary};
  max-width: ${props => props.progress}%;

  &:before {
    content: '';
    position: absolute;
    top: -9px;
    left: -2px;
    height: 20px;
    border-left: 2px solid ${({ theme }) => theme.primary};
  }

  &:after {
    position: absolute;
    top: -9px;
    right: 0;
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.primary};
  }
`;

const ProgressBarWrapper = styled.div`
  position: relative;
  top: -2px;
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const Wrapper = styled.div`
  position: relative;
  margin-right: auto;
  margin-left: auto;
  width: 1200px;
  max-width: 100%;
`;

const ProfileBlock = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing()} ${({ theme }) => theme.spacing()} 0
    ${({ theme }) => theme.spacing()};

  > * {
    margin-bottom: ${({ theme }) => theme.spacing()};
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  > * + * {
    margin-top: ${({ theme }) => theme.spacing()};
    margin-bottom: ${({ theme }) => theme.spacing()};
  }

  ${media.tablet`
  flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    > * + * {
      margin-top: ${({ theme }) => theme.spacing()};
    }
  `}
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  ${media.tablet`
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    > * + * {
      margin-top: ${({ theme }) => theme.spacing()};
    }
  `}
`;

const DetailsSection = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  background-color: #fff;
  border-radius: 4px;
  padding: ${({ theme }) => theme.spacing()};
  > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }
`;

const TextLight = styled.div`
  ${({ theme }) => theme.fontStyles.bodyLarge};
  color: ${({ theme }) => theme.textLighter};
`;

const TitleBlock = styled.h4`
  ${({ theme }) => theme.fontStyles.bodyLarger};
  margin: 0 0 ${({ theme }) => theme.spacing(0.5)};
`;

const Relative = styled.div`
  position: relative;
`;

const ProfilDetails = ({ location, profileId }) => {
  const { steps, lastCompletedStep, profile } = useProfileEditor({ profileId });
  const { getLanguagePrefix } = useContext(PageContext);
  const stepsRefs = steps.map(() => createRef());
  const { t } = useTranslation();
  const { state } = location;
  const [isOpen, setIsOpen] = useState(false);
  const [deleteStatus, setDeleteStatus] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const handleDelete = async () => {
    await setDeleteStatus(true);
    await deleteProfile(profileId);
    setIsOpen(false);
    navigate('/mon-compte');
  };

  useEffect(() => {
    if (!lastCompletedStep || !stepsRefs.length) {
      return;
    }
    stepsRefs[lastCompletedStep - 1].current.open();
    // eslint-disable-next-line
  }, [lastCompletedStep, stepsRefs.length]);

  return (
    <>
      <Wrapper>
        <Container>
          <TextLink variant="black" to={getLanguagePrefix(t('url.account'))}>
            <MdKeyboardArrowLeft size={22} />
            {t('buttons.back')}
          </TextLink>
        </Container>
      </Wrapper>
      <Relative>
        <ProgressLine />
        <ProgressBarWrapper>
          <ProgressBar
            progress={state.completionRate > 100 ? 100 : state.completionRate}
          />
        </ProgressBarWrapper>
      </Relative>
      <Wrapper style={{ marginTop: '100px' }}>
        <Container>
          <FlexWrapper>
            {state.completionRate !== 100 && (
              <div>
                <Paragraph style={{ maxWidth: '40rem' }}>
                  {t('profiles.details.text')}
                </Paragraph>
              </div>
            )}

            <PrimaryLink to="/politique-confidentialite">
              {t('profiles.details.privacy')}
            </PrimaryLink>
          </FlexWrapper>
          <SplitContainer>
            <LeftContainer>
              <DetailsSection>
                {steps.map((step, index) => (
                  <ProfileFields {...step} ref={stepsRefs[index]} />
                ))}
              </DetailsSection>
            </LeftContainer>
            <RightContainer hasNoTopMargin>
              <ProfileBlock>
                <TitleBlock>{t('profiles.details.completion')}</TitleBlock>
                <TextLight>
                  {`${profile.completionRate}% ${t(
                    'profiles.details.completed',
                  )}`}{' '}
                </TextLight>
                <TitleBlock>{t('profiles.details.updated')}</TitleBlock>
                <TextLight>{`${formatDate(profile.updatedAt)}`}</TextLight>
                <TitleBlock>{t('profiles.details.created')}</TitleBlock>
                <TextLight>{`${formatDate(profile.createdAt)}`}</TextLight>
              </ProfileBlock>
            </RightContainer>
          </SplitContainer>
          <ButtonsWrapper>
            <Button
              as={Link}
              to={getLanguagePrefix(t('url.account'))}
              variant="secondary"
            >
              {t('profiles.details.button')}
            </Button>
            <Button onClick={() => openModal()} variant="danger">
              {t('profiles.details.delete.button')}
            </Button>
          </ButtonsWrapper>
        </Container>

        <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
          <div style={{ paddingBottom: '32px' }}>
            <h3 style={{ textAlign: 'center' }}>
              {t('profiles.details.delete.confirmation')}
            </h3>
            {deleteStatus ? (
              <Spinner color="#fff" />
            ) : (
              <Buttons style={{ marginBottom: '24px' }} hasTopMargin>
                <Button
                  style={{ marginRight: '24px', width: '128px' }}
                  onClick={() => handleDelete()}
                  variant="danger"
                >
                  {t('profiles.details.delete.yes')}
                </Button>
                <Button
                  style={{ marginLeft: '24px', width: '128px' }}
                  onClick={() => setIsOpen(false)}
                  variant="primary"
                >
                  {t('profiles.details.delete.no')}
                </Button>
              </Buttons>
            )}
          </div>
        </Modal>
      </Wrapper>
    </>
  );
};

export default ProfilDetails;
