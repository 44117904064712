import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageContext from '../../../contexts/page';
import { convertToSlug } from '../../ConvertToSlug';
import ProfileTravelerList from './ProfileTravelerList';
import { Link } from 'gatsby';
import ProfileTravelerModal from './ProfileTravelerModal';

const ProfileTraveler = () => {
  const { t } = useTranslation();
  const { getLanguagePrefix } = useContext(PageContext);

  const [profileId, setProfileId] = useState(null);
  const [showProfilesList, setShowProfilesList] = useState(true);

  // TODO: Find a better way to refresh profiles
  useEffect(() => {
    if (!profileId) {
      setShowProfilesList(false);
      setTimeout(() => setShowProfilesList(true), 1);
    }
  }, [profileId]);

  return (
    <div className="flex flex-col space-y-20 pb-20">
      <div id={convertToSlug(t('profiles.header.profilesTraveler'))}>
        <div>
          <div className="text-4xl font-bold">{t('profiles.travelerProfile.mainTitle')}</div>
          <p className="my-8 text-gray-500">{t('profiles.travelerProfile.subtitle')}</p>
        </div>

        {showProfilesList &&
          <ProfileTravelerList setProfileId={setProfileId}/>
        }

        {profileId &&
          <ProfileTravelerModal
            profileId={profileId}
            setProfileId={setProfileId}
          />
        }
      </div>

      <div id={convertToSlug(t('profiles.header.profilesHowItWork'))}>
        <div>
          <div className="text-4xl font-bold">{t('profiles.travelerProfile.title')}</div>
          <div className="my-8">
            <Link className="text-brand-orange underline" to={getLanguagePrefix(t('url.privacy'))}>
              {t('profiles.travelerProfile.link')}
            </Link>
          </div>
        </div>

        <div className="flex flex-col space-y-4">
          {[
            {title: t('profiles.travelerProfile.step1.title'), text: t('profiles.travelerProfile.step1.text')},
            {title: t('profiles.travelerProfile.step2.title'), text: t('profiles.travelerProfile.step2.text')},
            {title: t('profiles.travelerProfile.step3.title'), text: t('profiles.travelerProfile.step3.text')}
          ].map((step, index) => (
            <div>
              <div className="text-lg font-bold">{index+1}. {step.title}</div>
              <p className="text-gray-500">{step.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProfileTraveler;
