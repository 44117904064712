import React from 'react';
import styled from 'styled-components';
import { Container } from 'ui/containers';
import { media } from 'ui';
import { useTranslation } from 'react-i18next';

const Title = styled.h2`
  color: #1A1A1A;
  font-size: 2rem;
  margin-bottom: 30px;
  margin-top: -30px;
  font-weight: 700;
  ${media.mobile`
    margin-left: 20px;
    font-size: 1.85rem;
    margin-top: 3px;
  `}
`

const Block = styled.div`
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
  max-width: 40em;
`;

const ProfilReview = () => {
  const { t } = useTranslation();
  
  return (
    <Container>
      <Title>{t('profiles.reviews.title')}</Title>

      <Block>
        <div>{t('profiles.reviews.noReviews')}</div>
      </Block>
    </Container>
  );
};

export default ProfilReview;
