import React, { useState } from 'react';
import { useAsync } from 'react-async';
import { createProfile, getProfiles } from '../../../api/profiles';
import ProfileTravelerListItem from './ProfileTravelerListItem';
import Button from '../../UI/Button';
import { useTranslation } from 'react-i18next';

export default function ProfileTravelerList({ setProfileId }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { data: { profiles } = {} } = useAsync({
    promiseFn: getProfiles,
  });

  const createProfileButton = async () => {
    setIsLoading(true);

    const { profile } = await createProfile({
      displayName: 'Nouveau profil',
    });

    setIsLoading(false);
    setProfileId(profile.id);
  }

  return (
    <div className="tw flex flex-col space-y-5">
      {(profiles || []).map(profile => (
        <ProfileTravelerListItem
          setProfileId={setProfileId}
          {...profile}
        />
      ))}

      <div className="w-full bg-gray-100 rounded-3xl">
        <div className="flex justify-between items-center p-8">
          <div className="opacity-50 text-lg font-semibold">{t('profiles.details.newProfile')}</div>

          <Button
            paddingClass="py-2 px-5 text-sm"
            onClick={() => createProfileButton()}
            loading={isLoading}
          >
            {t('utils.create')}
          </Button>
        </div>
      </div>
    </div>
  );
};
