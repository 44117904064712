import React from 'react';
import styled from 'styled-components';
import { GridContainer } from 'ui/containers';
import {Button, media } from 'ui';
import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async';
import { getHotelsLiked } from '../api/hotels';
import Spinner from './Spinner';
import { HotelCard } from 'ui/cards';
import { SpinnerContainer } from 'ui';
import { Link } from '@reach/router';

const Title = styled.h2`
  color: #1A1A1A;
  font-size: 2rem;
  margin-bottom: 40px;
  font-weight: 700;
  ${media.mobile`
    margin-left: 20px;
    font-size: 1.85rem;
  `}
`
const NoHotel  = styled.p`
  text-align: center;
`

const ProfilBucketList = () => {
  const { t } = useTranslation();

  const { data: hotels, isLoading } = useAsync({
    promiseFn: getHotelsLiked,
  });

  return (
    <>
        <Title>{t('profiles.bucket.title4')}</Title>
          {isLoading ?
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
           : 
           <>
            {hotels.length > 0 ? 
              <GridContainer variant="buketList">
                {(hotels || []).map(hotel => (
                    <HotelCard
                      {...hotel}
                      key={hotel.id}
                      openInNewTab
                      openEngagement
                      bucketlist
                    />
                  ))}
              </GridContainer>
              : <NoHotel>{t('profiles.bucket.noHotelLiked')} </NoHotel>}
              <Button
                hotels
                variant="carnet"
                bucketlist
                as={Link}
                to={'/'}
              >
                {t('profiles.bucket.button')}
              </Button>
            </>
          }
    </>
  );
};

export default ProfilBucketList;
