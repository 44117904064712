import React, { createRef, useState } from 'react';
import ProfileTravelerModalMenu from './ProfileTravelerModalMenu';
import Modal from '../../Modal';
import Button from '../../UI/Button';
import useProfileEditor from '../../../hooks/profileEditor';
import ProfileTravelerModalFields from './ProfileTravelerModalFields';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner';
import { deleteProfile } from '../../../api/profiles';
import Delete from '../../../assets/images/Delete.svg';
import styled from 'styled-components';
import {Buttons, media} from 'ui';


const DeleteWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 25px;
  right: 90px;
  color: #ffa24b;
  background-color: #ff9e9e;
  padding: 12px;
  border-radius: 100%;
  display: inline !important;
`;

const TextConfirmation = styled.div`
  margin-top: 40px;
  ${media.mobile`
  margin-top: 80px;
  `}

`;
export default function ProfileTravelerModal({ profileId, setProfileId }) {
  const { steps } = useProfileEditor({ profileId });
  const stepsRefs = steps.map(() => createRef());
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);

  // TODO: Detect actual loading
  setTimeout(() => setIsLoading(false), 14000);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const handleDelete = async () => {
    await setDeleteStatus(true);
    await deleteProfile(profileId);
    setIsOpen(false);
    setProfileId(null);
  };

  return (
    <Modal isOpen={true} onRequestClose={() => setProfileId(null)} scrollable variant="profile-traveler">
      <div className="bg-white rounded-3xl">
        <div className="flex">
          <div className="hidden lg:block">
            <div className="h-screen min-h-full w-72 bg-gray-100">
              <div className="flex justify-center">
                <div className="fixed top-20">
                  {!isLoading &&
                    <ProfileTravelerModalMenu
                      steps={steps}
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                    />
                  }
                </div>
              </div>
            </div>
          </div>

          <div className="relative w-full bg-white py-20">
            <div className="px-16">
              {!isLoading ? (
                <div>
                  <DeleteWrapper onClick={() => openModal()}>
                    <img src={Delete} alt="" style={{width:"25px", height: "20px", marginTop: "-5px"}} />
                  </DeleteWrapper>
                  {steps.map((step, index) => (
                    <ProfileTravelerModalFields
                      {...step}
                      ref={stepsRefs[index]}
                      hidden={index !== currentStep}
                    />
                  ))}

                  <div className="mt-6 flex justify-end">
                    {currentStep + 1 < steps.length ?
                      <Button onClick={() => setCurrentStep(currentStep + 1)}>{t('buttons.next')}</Button>
                      :
                      <Button onClick={() => setProfileId(null)}>{t('buttons.finish')}</Button>
                    }
                  </div>
                </div>
              ) : (
                <div className="lg:absolute lg:top-1/2 lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2">
                  <div className="text-center">
                    <img className="h-40 w-40" src={require('src/assets/svg/loading.svg')} alt='' />
                    <div className="mt-4 text-lg font-semibold">{t('profileTravelerModal.loading')}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isOpen} onRequestClose={() => setIsOpen(false)}>
          <div style={{ paddingBottom: '32px' }}>
            <TextConfirmation>
              <h3 style={{ textAlign: 'center' }}>
                {t('profiles.details.delete.confirmation')}
              </h3>
            </TextConfirmation>
            {deleteStatus ? (
              <Spinner color="#fff" />
            ) : (
              <Buttons style={{ marginBottom: '24px' }} hasTopMargin>
                <Button
                  style={{ marginRight: '24px', background:'#ff0000', color:'#ffffff', textTransform: 'uppercase', width: '128px' }}
                  onClick={() => handleDelete()}
                >
                  {t('profiles.details.delete.yes')}
                </Button>
                <Button
                  style={{ marginLeft: '24px', background:'#73c992', color:'#ffffff', textTransform: 'uppercase', width: '128px' }}
                  onClick={() => setIsOpen(false)}
                >
                  {t('profiles.details.delete.no')}
                </Button>
              </Buttons>
            )}
          </div>
        </Modal>
    </Modal>

    
  )
}
