import React, { useEffect, useState } from 'react';
import styled ,  { css } from 'styled-components';
import ProfileTraveler from 'components/Profile/Traveler/ProfileTraveler';
import ProfilBooking from 'components/ProfilBooking';
import ProfilBucketList from 'components/ProfilBucketList';
import ProfilReview from 'components/ProfilReview';
import ProfilMoney from 'components/ProfilMoney';
import ProfilAccount from 'components/ProfilAccount';
import { useTranslation } from 'react-i18next';
import { Container } from 'ui/containers';
import { media, Button as RawButton } from 'ui';
import { Select } from './Select';
import { convertToSlug } from './ConvertToSlug';
import {
  LeftTabContainer,
  RightTabContainer,
  SplitTabContainer,
} from 'ui/tabs';

const Button = styled(RawButton)`
  white-space: pre-line;
  text-align: left;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
  margin-left: 50px;
  text-transform: none;
  color: #212121;
  padding-top: 10px;
  ${props =>
    props.isActive &&
    css`
     
  `}
`;

const Item = styled.a`
  display: flex;
  align-items: center;
  background: none;
  justify-content: left;
  white-space: pre-line;
  text-align: left;
  margin-bottom: 6px !important;
  margin-left: 50px;
  text-transform: none;
  color: #212121;
  padding: 10px;
  text-decoration:none;
  font-size: 14px;
  ${props =>
    props.selected &&
    css`
      color: #000 !important;
      font-weight: bold;
      font-size: 14px;
      border-left: 2px solid #67CB8B;
    `}
`

const TabContent = styled.div`
`
const SubMenu = styled.div`
  padding-top: 3px;
  display: none;
  &.show {
    display: block;
  }  

  ${Item} {
    color: grey;
    padding-left: 16px;
    height: 25px;
    line-height: 1;
  }

`
const TabItem = styled.div`
  width: 90%;
  margin-left: 5%;
  padding-bottom: 7px;
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1.1;
  }
  ${props =>
    props.isActive &&
    css`
      background: #F7F7F7 0% 0% no-repeat padding-box;
      border-radius: 18px;
      opacity: 1;
  `}
`

const MenuTile = styled.div`
  width: 90%;
  margin: 20px 0 20px 8%;
  text-align: left;
  font: normal normal bold 26px/48px Muli;
  letter-spacing: -0.42px;
  > span {
    color: #67CB8B;
  }
`

const MobileMenuContainer = styled.div`
  width: 90%;
  max-width: 90%;
  display: none;
  padding-top: 35px;
  background-color: white;
  z-index: 10;
  
  ${Button} {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    margin-right: ${({ theme }) => theme.spacing()};
  }
  ${media.tablet`
    max-width: 300px;
    margin-top: ${({ theme }) => theme.spacing(0.5)};
  `};

  ${media.mobile`
    position: fixed;
    display: block;
  `}
`


const Profile = () => {
  const { t } = useTranslation();
  const [contentSelected, setContentSelected] = useState(0);
  const [subContentSelected, setSubContentSelected] = useState('informations');
  const [dropdown, setDropdown] = useState(true);

  const handleClick = (index) => {
    setContentSelected(index)
    setDropdown(true);
  };

  const selectedSubMenu = (selected) =>{
    setSubContentSelected(selected);
  };

  useEffect(() => {
    if (tabElements[contentSelected].submenu) {
     setSubContentSelected(convertToSlug(tabElements[contentSelected].submenu[0].title));
    }
    setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 100);
  },[contentSelected]) // eslint-disable-line react-hooks/exhaustive-deps

  const ScrollToTop = () => {
    const  windowWidth = window.innerWidth;
    var marginTop = 80 
     if (windowWidth < 750) {
       marginTop = 5
     }
     window.scrollTo({
       top: marginTop,
       behavior: 'smooth',
     });
   }

   const ScrollToTopSubMenu = (id) => {
     const element = document.getElementById(id)
     element.scrollIntoView(true);
     window.scrollBy(0, -120);

   }
  const tabElements = [
    { label: t('profiles.header.account'),
      submenu: [
        {
         title: t('profiles.header.accountInfo')
        },
        {
          title: t('profiles.header.accountPassword')
        },
        {
          title: t('profiles.header.accountPay')
        }
      ]
    },
    { label: t('profiles.header.bookings'),
      submenu: [
        {
          title: t('profiles.header.bookingToCome')
        },
        {
          title: t('profiles.header.bookingPrevious')
        },
        {
          title: t('profiles.header.bookingCancelled')
        }
      ]
    },

    { label: t('profiles.header.profile'),
      submenu: [
        {
          title: t('profiles.header.profilesTraveler'),
        },
        {
          title: t('profiles.header.profilesHowItWork'),
        },
      ]
    },
    { label: t('profiles.header.buckets') },
    { label: t('profiles.header.newreviews') },
    { label: t('profiles.header.money'),
      submenu: [
        {
          title: t('profiles.header.money')
        },
        {
          title: t('profiles.header.moneySponsorship'),
        },
      ]
    },
  ];

  const tabContents = [
    <ProfilAccount/>,
    <ProfilBooking />,
    <ProfileTraveler />,
    <ProfilBucketList />,
    <ProfilReview />,
    <ProfilMoney />,
  ];

  return (
    <>
      <Container>
      <SplitTabContainer menuContainer>
        <MobileMenuContainer>
          <Select
            name="menu"
            onChange={input => {
              handleClick(input.value, 'menu');
              ScrollToTop();
            }}
            options={[
              ...(tabElements || []).map((result, index) => ({
                label: result.label,
                value: index,
              })),
            ]}
            value={contentSelected}
            placeholder={tabElements[contentSelected].label}
            variant={'menu'}
            type={'article'}
          />
        </MobileMenuContainer>

        <LeftTabContainer sideMenu>
          <MenuTile>Mon <span>espace</span></MenuTile>
          {tabElements.map((tab, index) => (
            tab.submenu ? (
              <TabItem isActive={contentSelected === index} onClick={() => handleClick(index)}>
                <Button
                  variant="tab"
                  isActive={contentSelected === index}
                  
                  onClick={() => {
                    handleClick(index);
                    ScrollToTop();
                  }}
                >
                  {tab.label}
                </Button>
                <SubMenu className={`${dropdown && contentSelected === index ? "show" : ""}`}>
                  {tab.submenu.map((subTab) => (
                    <Item
                      variant="tab"
                      selected = {subContentSelected === convertToSlug(subTab.title)}
                      // href={'#'+ convertToSlug(subTab.title)}
                      onClick={() => {
                        selectedSubMenu(convertToSlug(subTab.title));
                        ScrollToTopSubMenu(convertToSlug(subTab.title));
                      }}
                    >
                      {subTab.title}
                    </Item>
                  ))}
                </SubMenu>
              </TabItem>
            ) :
            <TabItem isActive={contentSelected === index}
              onClick={e => {
                e.preventDefault();
                setContentSelected(index);
              }}
            >
              <Button
                variant="tab"
                isActive={contentSelected === index}
                onClick={e => {
                  e.preventDefault();
                  ScrollToTop();
                  setContentSelected(index);
                }}
              >
                {tab.label}
              </Button>
            </TabItem>


           ))}
          <div className="h-3"/>
        </LeftTabContainer>

        <RightTabContainer menuContent>
          <div className="-sm:mt-28">
            <TabContent style={{ outline: 'none', marginTop: 20 }}>
              {tabContents[contentSelected]}
            </TabContent>
          </div>
        </RightTabContainer>
      </SplitTabContainer>
    </Container>
    </>

  );
};

export default Profile;
